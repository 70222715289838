/**
 * Bind action to video element
 *
 * @returns {void}
 */
export default class Popup {
  /**
  * Constructor
  *
  * @returns {void}
  */
  constructor() {
    // Set scope
    const scope = document.body;

    this.boundTogglePopup = (e) => this.togglePopup(e);

    // Get elements
    this.actionButton = scope.querySelector('.link-action');
    this.popup = scope.querySelector('.popup-overlay');
    this.closeButton = scope.querySelector('.popup-close');
  }

  /**
  * Add event listeners
  *
  * @returns {void}
  */
  init() {
    if (this.actionButton) {
      // Toggle popup show on button click
      this.actionButton.addEventListener('click', this.boundTogglePopup, true);
    }

    if (this.closeButton) {
      this.closeButton.addEventListener('click', this.boundTogglePopup, false);
    }
  }

  /**
  * Bind action to video element
  *
  * @param {ClickEvent} e - Event from the click listener
  *
  * @returns {void}
  */
  togglePopup(e) {
    e.preventDefault();

    // Toggle the popup
    this.popup.classList.toggle('show-popup');
  }
}
