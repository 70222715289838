/**
 * Bind action to hidden about input element
 *
 * @returns {void}
 */
export default class IndicatorsInput {
  /**
   * Constructor
   *
   * @returns {void}
   */
  constructor() {
    // Set scope
    const scope = document.body;

    this.boundCheckCheckbox = (e) => this.checkCheckbox(e);

    // Get elements
    this.addIndicatorsCheckboxes = scope.querySelectorAll(
      '.add-indicators-checkbox',
    );
    this.selectedIndicatorsTitle = scope.querySelector(
      '.selected-indicators-title',
    );
  }

  /**
   * Add event listeners
   *
   * @returns {void}
   */
  init() {
    if (this.addIndicatorsCheckboxes) {
      this.addIndicatorsCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('click', this.boundCheckCheckbox, false);
      });
    }
  }

  /**
   * Add event listeners
   *
   * @param {Event} e - Event
   *
   * @returns {void}
   */
  checkCheckbox(e) {
    const animationDuration = 250;
    const checkbox = e.currentTarget;

    checkbox.disabled = true;
    setTimeout(() => {
      checkbox.disabled = false;
    }, animationDuration);

    /**
     * Check if a checkbox is checked
     *
     * @param {Array} arr - All checkboxes
     *
     * @returns {void}
     */
    const checker = (arr) => arr.every((v) => v.checked === false);
    const checkboxArr = Array.prototype.slice.call(
      this.addIndicatorsCheckboxes,
    );

    if (checker(checkboxArr)) {
      this.selectedIndicatorsTitle.classList.add('d-none');
    } else {
      this.selectedIndicatorsTitle.classList.remove('d-none');
    }
  }
}
