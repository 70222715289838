import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/modal';
import Popover from 'bootstrap/js/dist/popover';
import CircleProgress from 'js-circle-progress';
import Video from './Video';
import Popup from './Popup';
import HiddenTitleInput from './HiddenTitleInput';
import LocationInput from './LocationInput';
import SubjectsInput from './SubjectsInput';
import TextareaPlaceholder from './TextareaPlaceholder';
import ImageInput from './ImageInput';
import IndicatorsInput from './IndicatorsInput';

/**
 * Initialize functions on load
 *
 * @returns {void}
 */
const init = () => {
  // Video
  const video = new Video();
  video.init();

  const hiddenTitleInput = new HiddenTitleInput();
  hiddenTitleInput.init();

  const locationInput = new LocationInput();
  locationInput.init();

  const subjectsInput = new SubjectsInput();
  subjectsInput.init();

  const textareaPlaceholder = new TextareaPlaceholder();
  textareaPlaceholder.init();

  const imageInput = new ImageInput();
  imageInput.init();

  const indicatorsInput = new IndicatorsInput();
  indicatorsInput.init();

  const progressElements = [].slice.call(
    document.querySelectorAll('.progress'),
  );

  progressElements.map(
    (element) => new CircleProgress(element, {
      value: element.getAttribute('aria-valuenow'),
      max: 100,
      textFormat: 'none',
    }),
  );

  const popoverTriggerList = document.querySelectorAll(
    '[data-bs-toggle="popover"]',
  );
  popoverTriggerList.forEach((popoverTriggerEl) => {
    (() => new Popover(popoverTriggerEl))();

    popoverTriggerEl.addEventListener('show.bs.popover', () => {
      document.querySelector('.main').classList.add('main-popover');
    });

    popoverTriggerEl.addEventListener('hide.bs.popover', () => {
      document.querySelector('.main').classList.remove('main-popover');
    });
  });

  const carouselList = document.querySelectorAll('.carousel');
  carouselList.forEach((carouselEl) => {
    carouselEl.addEventListener('slide.bs.carousel', (e) => {
      const total = carouselEl.getAttribute('data-length');

      if (e.to === 0) {
        carouselEl.setAttribute('data-to', 'first');
      } else if ((e.to + 1).toString() === total) {
        carouselEl.setAttribute('data-to', 'last');
      } else {
        carouselEl.setAttribute('data-to', e.to);
      }
    });
  });

  const toggleProfileEditorList = document.querySelectorAll('[data-cu-toggle="edit"]');
  toggleProfileEditorList.forEach((editorToggleEl) => {
    (() => new Popover(editorToggleEl))();

    const editSelector = editorToggleEl.getAttribute('data-cu-target');
    const profileEditEl = document.querySelector(editSelector);

    const contentselector = editorToggleEl.getAttribute('data-cu-hide');
    const hideContenttEl = document.querySelector(contentselector);

    editorToggleEl.addEventListener('click', () => {
      profileEditEl.classList.add('show-editor');
      hideContenttEl.classList.add('hide-content');
    });

    profileEditEl.querySelector('[data-cu-toggle="save"]').addEventListener('click', () => {
      profileEditEl.classList.remove('show-editor');
      hideContenttEl.classList.remove('hide-content');
    });

    profileEditEl.querySelector('[data-cu-toggle="close"]').addEventListener('click', () => {
      profileEditEl.classList.remove('show-editor');
      hideContenttEl.classList.remove('hide-content');
    });
  });

  // Popup lecturer dashboard
  const popup = new Popup();
  popup.init();
};

window.addEventListener('load', init, false);
