/**
 * Bind action to hidden title input element
 *
 * @returns {void}
 */
export default class ImageInput {
  /**
   * Create a file input
   *
   * @returns {void}
   */
  static createFileInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.disabled = true;
    input.classList.add('hidden-image-input');

    return input;
  }

  /**
   * Create a preview
   *
   * @returns {void}
   */
  static createPreviewImage() {
    const image = document.createElement('img');
    image.classList.add('preview-image');
    return image;
  }

  /**
   * Get all the previews per row
   *
   * @returns {void}
   */
  static getPreviewsPerRow() {
    const width = window.innerWidth;
    if (width < 600) {
      return 2;
    }
    if (width >= 600 && width < 900) {
      return 3;
    }
    return 4;
  }

  /**
   * Constructor
   *
   * @returns {void}
   */
  constructor() {
    // Set scope
    const scope = document.body;

    this.boundHightlightDropzone = (e) => this.hightlightDropzone(e);
    this.boundUploadImage = (e) => this.uploadImage(e);
    this.boundDeleteImage = (e) => this.deleteImage(e);

    // Get elements
    this.uploadImageInputs = scope.querySelectorAll('.upload-image-input');
    this.imagePreviewWrappers = scope.querySelectorAll(
      '.image-preview-wrapper',
    );
    this.deleteImageButtons = scope.querySelectorAll('.delete-image-button');
  }

  /**
   * Add event listeners
   *
   * @returns {void}
   */
  init() {
    if (this.uploadImageInputs) {
      this.uploadImageInputs.forEach((input) => {
        input.addEventListener('change', this.boundUploadImage, false);
      });

      this.deleteImageButtons.forEach((button) => {
        button.addEventListener('click', this.boundDeleteImage, false);
      });
    }
  }

  /**
   * Add event listeners
   *
   * @param {Event} e - Event
   *
   * @returns {void}
   */
  uploadImage(e) {
    // Needs to be a least common multiple of 2, 3 and 4 minus 1. A.k.a 12 - 1 = 11
    const indexFactor = 11;
    const inputIndex = Array.prototype.indexOf.call(
      this.uploadImageInputs,
      e.currentTarget,
    );
    const filesArr = Array.from(this.uploadImageInputs[inputIndex].files);
    const previewsPerRow = ImageInput.getPreviewsPerRow();

    if (filesArr.length >= indexFactor) return;

    filesArr.forEach((file, index) => {
      const dt = new DataTransfer();
      dt.items.add(new File([file.slice(0, file.size, file.type)], file.name));
      this.input = ImageInput.createFileInput();
      const image = ImageInput.createPreviewImage();
      this.input.files = dt.files;
      image.src = URL.createObjectURL(file);

      let i = index + inputIndex * indexFactor;
      while (i < indexFactor + inputIndex * indexFactor) {
        if (this.imagePreviewWrappers[i].children.length <= 1) {
          this.imagePreviewWrappers[i].appendChild(this.input);
          this.imagePreviewWrappers[i].appendChild(image);
          this.imagePreviewWrappers[i].classList.remove('d-none');
          this.imagePreviewWrappers[i].classList.add('d-flex');
          this.deleteImageButtons[i].classList.remove('d-none');

          const inputIndexAddition = Math.floor(i / indexFactor) + 1;

          i += inputIndexAddition;

          if (i % previewsPerRow === 0) {
            for (
              let j = i - inputIndexAddition;
              j < i + previewsPerRow - inputIndexAddition;
              j += 1
            ) {
              this.imagePreviewWrappers[j].classList.remove('d-none');
              this.imagePreviewWrappers[j].classList.add('d-flex');
            }
          }

          break;
        }
        i += 1;
      }
    });
  }

  /**
   * Delete an image
   *
   * @param {Event} e - Event
   *
   * @returns {void}
   */
  deleteImage(e) {
    const indexFactor = 11;
    const parent = e.target.parentElement;
    const previewsPerRow = ImageInput.getPreviewsPerRow();
    const inputIndex = Array.prototype.indexOf.call(
      this.deleteImageButtons,
      e.currentTarget,
    );
    const inputIndexAddition = Math.floor(inputIndex / indexFactor) + 1;

    let firstRowIndex = Math.floor(
      (inputIndex + inputIndexAddition) / previewsPerRow,
    ) * previewsPerRow - inputIndexAddition;
    if (firstRowIndex < 0) {
      firstRowIndex = 0;
    }
    let lastRowIndex = Math.ceil(
      (inputIndex + inputIndexAddition) / previewsPerRow,
    ) * previewsPerRow - inputIndexAddition;
    if (firstRowIndex === lastRowIndex) {
      lastRowIndex += previewsPerRow;
    }

    e.target.classList.add('d-none');
    parent.replaceChildren(...[e.target]);

    let emptyElements = 0;
    for (let j = firstRowIndex; j < lastRowIndex; j += 1) {
      if (this.imagePreviewWrappers[j].children.length <= 1) {
        emptyElements += 1;
      }
    }

    if (emptyElements === previewsPerRow) {
      for (let i = firstRowIndex; i < lastRowIndex; i += 1) {
        this.imagePreviewWrappers[i].classList.remove('d-flex');
        this.imagePreviewWrappers[i].classList.add('d-none');
      }
    }
  }
}
