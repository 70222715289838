/**
 * Bind action to hidden title input element
 *
 * @returns {void}
 */
export default class HiddenTitleInput {
  /**
   * Constructor
   *
   * @returns {void}
   */
  constructor() {
    // Set scope
    const scope = document.body;

    this.boundEmptyTitleInput = (e) => this.emptyTitleInput(e);
    this.boundUpdateHiddenTitleInput = (e) => this.updateHiddenTitleInput(e);
    this.boundResetTitleInput = (e) => this.resetTitleInput(e);
    this.boundFocusTitleInput = (e) => this.focusTitleInput(e);

    // Get elements
    this.titleInput = scope.querySelector('.title-input');
    this.hiddenTitleInput = scope.querySelector('.hidden-title-input');
    this.focusTitleInputWrapper = scope.querySelector(
      '.focus-title-input-wrapper',
    );
  }

  /**
   * Add event listeners
   *
   * @returns {void}
   */
  init() {
    if (this.titleInput) {
      // Empty the title input
      this.titleInput.addEventListener(
        'focusin',
        this.boundEmptyTitleInput,
        false,
      );

      // Update the hidden title input
      this.titleInput.addEventListener(
        'input',
        this.boundUpdateHiddenTitleInput,
        false,
      );

      // Reset the placeholder
      this.titleInput.addEventListener(
        'focusout',
        this.boundResetTitleInput,
        false,
      );
    }

    if (this.focusTitleInputWrapper) {
      // Focus the title input
      this.focusTitleInputWrapper.addEventListener(
        'click',
        this.boundFocusTitleInput,
        false,
      );
    }
  }

  /**
   * Bind action to the title input element
   *
   * @param {Event} e - Event
   *
   * @returns {void}
   */
  emptyTitleInput(e) {
    e.preventDefault();

    const bool = this.compareInputToPlaceholder();

    if (!bool) return;

    this.titleInput.innerHTML = '\u00a0';
    this.titleInput.focus(); // Firefox
    this.focusStartOfTitleInput();
  }

  /**
   * Bind action to the hidden title input element
   *
   * @param {Event} e - Event
   *
   * @returns {void}
   */
  updateHiddenTitleInput(e) {
    e.preventDefault();

    // Set the value of the hidden title input element
    this.hiddenTitleInput.value = this.titleInput.textContent;
  }

  /**
   * Bind action to the title input element
   *
   * @param {Event} e - Event
   *
   * @returns {void}
   */
  resetTitleInput(e) {
    e.preventDefault();

    if (this.titleInput.textContent !== '') return;

    this.titleInput.textContent = '[Bedenk een titel voor je bewijskaart]';
  }

  /**
   * Bind action to the focus title input Wrapper
   *
   * @param {Event} e - Event
   *
   * @returns {void}
   */
  focusTitleInput(e) {
    e.preventDefault();

    const bool = this.compareInputToPlaceholder();

    if (bool) {
      this.titleInput.innerHTML = '\u00a0';
      this.titleInput.focus(); // Firefox
      this.focusStartOfTitleInput();
    } else {
      this.focusEndOfTitleInput();
    }
  }

  /**
   * Compare the input to the placeholder
   *
   * @returns {void}
   */
  compareInputToPlaceholder() {
    const placeholder = '[Bedenk een titel voor je bewijskaart]';
    const string = this.titleInput.textContent;
    const subString = string.substring(
      string.indexOf('['),
      string.indexOf(']') + 1,
    );

    return subString === placeholder;
  }

  /**
   *
   * @returns {void}
   */
  focusStartOfTitleInput() {
    const s = window.getSelection();
    const r = document.createRange();

    r.selectNodeContents(this.titleInput);
    s.removeAllRanges();
    s.addRange(r);
    document.execCommand('delete', false, null);
  }

  /**
   *
   * @returns {void}
   */
  focusEndOfTitleInput() {
    const s = window.getSelection();
    const r = document.createRange();

    r.setStart(this.titleInput, 1);
    r.setEnd(this.titleInput, 1);
    s.removeAllRanges();
    s.addRange(r);
  }
}
