/**
 * Bind action to hidden about input element
 *
 * @returns {void}
 */
export default class LocationInput {
  /**
   * Constructor
   *
   * @returns {void}
   */
  constructor() {
    // Set scope
    const scope = document.body;

    this.boundUpdateButton = (e) => this.updateButton(e);
    this.boundUpdateCardTypeButton = (e) => this.updateCardTypeButton(e);
    this.boundUpdateEducationsButton = (e) => this.updateEducationsButton(e);

    // Get elements
    this.educationsButton = scope.querySelector('.educations-button');
    this.educationRadioButtons = scope.querySelectorAll(
      '.education-radio-button',
    );

    this.cardTypesButton = scope.querySelector('.card-type-button');
    this.cardTypeRadioButtons = scope.querySelectorAll(
      '.card-type-radio-button',
    );

    this.locationsButton = scope.querySelector('.locations-button');
    this.locationRadioButtons = scope.querySelectorAll(
      '.location-radio-button',
    );
  }

  /**
   * Add event listeners
   *
   * @returns {void}
   */
  init() {
    if (this.locationRadioButtons) {
      this.locationRadioButtons.forEach((button) => {
        button.addEventListener('click', this.boundUpdateButton, false);
      });
    }

    if (this.cardTypeRadioButtons) {
      this.cardTypeRadioButtons.forEach((button) => {
        button.addEventListener('click', this.boundUpdateCardTypeButton, false);
      });
    }

    if (this.educationRadioButtons) {
      this.educationRadioButtons.forEach((button) => {
        button.addEventListener('click', this.boundUpdateEducationsButton, false);
      });
    }
  }

  /**
   * Add event listeners
   *
   * @param {ClickEvent} e - Event from the click listener
   *
   * @returns {void}
   */
  updateEducationsButton(e) {
    this.educationsButton.textContent = e.target.value;
  }

  /**
   * Add event listeners
   *
   * @param {ClickEvent} e - Event from the click listener
   *
   * @returns {void}
   */
  updateCardTypeButton(e) {
    this.cardTypesButton.textContent = e.target.value;
  }

  /**
   * Add event listeners
   *
   * @param {ClickEvent} e - Event from the click listener
   *
   * @returns {void}
   */
  updateButton(e) {
    this.locationsButton.textContent = e.target.value;
  }
}
