/**
 * Bind action to hamburger menu
 *
 * @returns {void}
 */
export default class TextareaPlaceholder {
  /**
   * Toggle the menu
   *
   * @param {ClickEvent} e - Event from the click listener
   *
   * @returns {void}
   */
  static togglePlaceholder(e) {
    if (e.target.value !== e.target.textContent) {
      e.target.nextElementSibling.textContent = '';
    } else {
      e.target.nextElementSibling.textContent = e.target.placeholder;
    }
  }

  /**
   * Toggle the menu
   *
   * @param {ClickEvent} e - Event from the click listener
   *
   * @returns {void}
   */
  static toggleValuePlaceholder(e) {
    if (e.target.value === '') {
      e.target.nextElementSibling.textContent = e.target.placeholder;
      e.target.value = e.target.textContent;
    }
  }

  /**
   * Constructor
   *
   * @returns {void}
   */
  constructor() {
    // Get scope
    const scope = document.body;

    this.boundTogglePlaceholder = (e) => TextareaPlaceholder.togglePlaceholder(e);
    this.boundToggleValuePlaceholder = (e) => TextareaPlaceholder.toggleValuePlaceholder(e);

    // Get elements
    this.textareas = scope.querySelectorAll('.answer-textarea-aid');
  }

  /**
   * Add event listeners
   *
   * @returns {void}
   */
  init() {
    if (this.textareas) {
      this.textareas.forEach((textarea) => {
        textarea.addEventListener('input', this.boundTogglePlaceholder, false);
      });

      this.textareas.forEach((textarea) => {
        textarea.addEventListener('focusout', this.boundToggleValuePlaceholder, false);
      });
    }
  }
}
