/**
 * Bind action to hidden about input element
 *
 * @returns {void}
 */
export default class SubjectsInput {
  /**
   * Constructor
   *
   * @returns {void}
   */
  constructor() {
    // Set scope
    const scope = document.body;

    this.boundUncheckCheckbox = (e) => this.uncheckCheckbox(e);
    this.boundToggleTitle = (e) => this.toggleTitle(e);

    // Get elements
    this.addSubjectCheckboxes = scope.querySelectorAll('.add-subject-checkbox');
    this.removeSubjectButtons = scope.querySelectorAll(
      '.remove-subject-button',
    );
    this.selectedSubjectsTitle = scope.querySelector(
      '.selected-subjects-title',
    );
    this.dropdownToggleIndicators = scope.querySelector(
      '.dropdown-toggle-indicators',
    );
  }

  /**
   * Add event listeners
   *
   * @returns {void}
   */
  init() {
    if (this.removeSubjectButtons) {
      this.removeSubjectButtons.forEach((button) => {
        button.addEventListener('click', this.boundUncheckCheckbox, false);
      });
    }

    if (this.addSubjectCheckboxes) {
      this.addSubjectCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('click', this.boundToggleTitle, false);
      });
    }
  }

  /**
   * Add event listeners
   *
   * @param {ClickEvent} e - Event from the click listener
   *
   * @returns {void}
   */
  uncheckCheckbox(e) {
    e.preventDefault();

    const index = Array.prototype.indexOf.call(
      this.removeSubjectButtons,
      e.currentTarget,
    );
    const secondIndex = this.getSecondIndex(index);

    this.addSubjectCheckboxes[index].checked = false;
    this.addSubjectCheckboxes[secondIndex].checked = false;

    this.checkCheckboxes();
  }

  /**
   * Add event listeners
   *
   * @param {ClickEvent} e - Event from the click listener
   *
   * @returns {void}
   */
  toggleTitle(e) {
    const animationDuration = 100;
    const index = Array.prototype.indexOf.call(
      this.addSubjectCheckboxes,
      e.currentTarget,
    );
    const secondIndex = this.getSecondIndex(index);

    if (e.currentTarget.checked) {
      this.addSubjectCheckboxes[secondIndex].checked = true;
    } else {
      this.addSubjectCheckboxes[secondIndex].checked = false;
    }

    e.currentTarget.disabled = true;
    this.addSubjectCheckboxes[secondIndex].disabled = true;
    setTimeout(() => {
      this.addSubjectCheckboxes[index].disabled = false;
      this.addSubjectCheckboxes[secondIndex].disabled = false;
    }, animationDuration);

    this.checkCheckboxes();
  }

  /**
   * Checks all addSubjectCheckboxes and displays the title accordingly
   *
   * @returns {void}
   */
  checkCheckboxes() {
    /**
     * Check if a checkbox is checked
     *
     * @param {Array} arr - All checkboxes
     *
     * @returns {void}
     */
    const checker = (arr) => arr.every((v) => v.checked === false);
    const checkboxArr = Array.prototype.slice.call(this.addSubjectCheckboxes);

    if (checker(checkboxArr)) {
      this.selectedSubjectsTitle.classList.add('d-none');
      this.dropdownToggleIndicators.textContent =
        '- Kies 1 of meer werkprocessen -';
    } else {
      this.selectedSubjectsTitle.classList.remove('d-none');
      this.dropdownToggleIndicators.textContent = `Je hebt ${this.getCheckedToggleText()} werkprocessen geselecteerd`;
    }
  }

  /**
   * Get the second index
   *
   * @param {int} index - Index of the second checkbox
   *
   * @returns {void}
   */
  getSecondIndex(index) {
    let secondIndex;
    if (index < this.addSubjectCheckboxes.length / 2) {
      secondIndex = index + this.addSubjectCheckboxes.length / 2;
    } else {
      secondIndex = index % (this.addSubjectCheckboxes.length / 2);
    }
    return secondIndex;
  }

  /**
   *
   * @returns {void}
   */
  getCheckedToggleText() {
    const count =
      Array.prototype.slice
        .call(this.addSubjectCheckboxes)
        .filter((checkbox) => checkbox.checked === true).length / 2;
    return count;
  }
}
