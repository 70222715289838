/**
 * Bind action to video element
 *
 * @returns {void}
 */
export default class Video {
  /**
   * Constructor
   *
   * @returns {void}
   */
  constructor() {
    // Set scope
    const scope = document.body;

    this.boundToggleThumbnail = (e) => this.toggleThumbnail(e);

    // Get elements
    this.playButton = scope.querySelector('.play-button');
    this.thumbnail = scope.querySelector('.thumbnail');
    this.video = scope.querySelector('.video');
  }

  /**
   * Add event listeners
   *
   * @returns {void}
   */
  init() {
    if (this.playButton) {
      // Toggle thumbnail on play button click
      this.playButton.addEventListener(
        'click',
        this.boundToggleThumbnail,
        false,
      );
    }
  }

  /**
   * Bind action to video element
   *
   * @param {ClickEvent} e - Event from the click listener
   *
   * @returns {void}
   */
  toggleThumbnail(e) {
    e.preventDefault();

    // Toggle the thumbnail
    this.thumbnail.classList.toggle('display-none');

    // Start the video
    this.video.play();
  }
}
